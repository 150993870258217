import axios from "@/plugins/axios";

export default {
    async buscar(id) {
        return await axios.get(`convenios/${id}`);
    },
    
    async inserir(dados) {
        return await axios.post("convenios", dados);
    },
    
    async atualizar(id, dados) {
        return await axios.put(`convenios/${id}`, dados);
    },
};